import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Menu from '../components/Menu'
import Page from '../components/Page'

export default props => {
  const { title, subtitle, menu } = props.data.site.siteMetadata

  return (
    <Layout className="overflow-hidden" isHome={true}>
      <Helmet title={`Cagemannerisms — ${title}`}>
        <meta name="description" content={subtitle} />
      </Helmet>
      <Menu data={menu} />
      <Page className="items-center justify-center m-auto">
        <h1 className="font-hairline mb-4 text-4xl lg:text-headline uppercase text-gray-800">
          Cagemannerisms
        </h1>
        <h2 className="font-light mt-4 pt-0 text-sm md:text-base text-gray-500">
          by Dimitry{' '}
          <span className="text-green-500">&quot;The Cageman&quot;</span>{' '}
          Kooijmans
        </h2>
      </Page>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
        }
      }
    }
  }
`
